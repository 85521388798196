<template>
  <section id="kits-tab">
    <Header title="Kits" tooltip="Gerenciar kits" action="Montar kit"
      @action="action" @print="print" @xlsx="xlsx" class="no-print">
    </Header>

    <Filters @update="updateFilters" class="no-print"/>

    <Table :kits="kits" @edit="edit"
      @delete="deleteModal" :isLoading="loading">
    </Table>

    <div class="pagination-position no-print" v-show="kits?.length && !loading">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
      />
    </div>

    <KitModal :kit="kit" @close="onCloseModal" @save="onSave"/>
  </section>
</template>

<script>
import { saveAs } from 'file-saver';

export default {
  name: 'kits-tab',
  components: {
    Header: () => import('@/components/General/TabHeader.vue'),
    Filters: () => import('@/components/Warehouse/Kits/KitFilters.vue'),
    Table: () => import('@/components/Warehouse/Kits/KitsTable.vue'),
    KitModal: () => import('@/components/Warehouse/Kits/KitModal.vue'),
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      kit: null,
      kits: [],
      filters: {},
      productOptions: [],
      page: 1,
      limit: 10,
      count: 0,
      loading: false,
    }
  },
  async created () {
    await this.getKits()
  },
  methods: {
    action () {
      this.$bvModal.show('create-kit-modal')
    },
    print () {
      window.print();
    },
    xlsx() {
      const isLoading = this.$loading.show();
      this.api.exportKits(this.clinic.id, this.filters)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'kits.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    edit (kit) {
      this.kit = JSON.parse(JSON.stringify(kit))
      this.$bvModal.show('create-kit-modal')
    },
    async deleteModal(kitId) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Você tem certeza que deseja remover o kit?
          <p>Não será possível reverter essa ação.</p>
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteKit(kitId)
        }
      })
    },
    async deleteKit (kitId) {
      try {
        this.loading = true
        await this.api.deleteKit(kitId)
        this.$toast.success('Kit excluído com sucesso')
      } catch (error) {
        this.$toast.error('Erro ao excluir kit')
      } finally {
        this.loading = false
        await this.getKits()
      }
    },
    onCloseModal () {
      this.kit = null
    },
    async onSave () {
      this.$bvModal.hide('create-kit-modal')
      await this.getKits()
    },
    async updateFilters (filters) {
      this.filters = filters
      await this.getKits()
    },
    async getKits () {
      try {
        this.loading = true
        const res = await this.api.getKits(this.clinic.id, this.filters, this.page)

        this.kits = res.data.data
        this.count = res.data.total
        this.limit = res.data.per_page
        
      } catch (error) {
        this.$toast.error('Erro ao buscar kits')
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    page: async function () {
      await this.getKitTemplates()
    },
  }
}
</script>